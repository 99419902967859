const Home = () => import("@/views/Home");
const routes = [
    {
        path: "/",
        redirect: {
            name: "home",
        },
    },
    {
        path: "/home",
        component: () => import('@/layouts'),
        children: [
            { path: '/', name: 'home', component: Home, },
        ]
    }
];
export default routes;
